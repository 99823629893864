<template>
  <div class="game-list-container">
    <!-- 搜索区域 -->
    <div class="search-section">
      <div class="search-header">
        <h2 class="section-title">PlayStation 游戏库</h2>
        <p class="section-subtitle">探索PS平台精彩游戏，开启次世代游戏体验</p>
      </div>
      <div class="search-box">
        <el-input v-model="psinput"
                  placeholder="搜索游戏名称"
                  prefix-icon="el-icon-search"
                  clearable
                  class="search-input">
        </el-input>
        <el-select v-model="psinput1"
                   clearable
                   placeholder="选择区服"
                   class="region-select">
          <el-option v-for="item in region"
                     :key="item.id"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="psinput2"
                   clearable
                   filterable
                   placeholder="选择语言"
                   class="language-select">
          <el-option v-for="item in pslanguega"
                     :key="item"
                     :label="item"
                     :value="item">
          </el-option>
        </el-select>
        <el-button type="primary" @click="pssearch" class="search-btn">
          <i class="el-icon-search"></i> 搜索
        </el-button>
        <el-button type="success" @click="psdialogFormVisible = true" class="add-btn">
          <i class="el-icon-user"></i> 获取账户ID
        </el-button>
      </div>
    </div>

    <!-- 游戏列表 -->
    <div class="game-grid" v-loading="loading" element-loading-text="加载中...">
      <el-card v-for="i in psgameList"
               :key="i.id"
               class="game-card"
               @click.native="changeGopsgame(i)"
               shadow="hover">
        <div class="game-image">
          <img :src='i.picmulu' class="game-cover"/>
          <div class="game-overlay">
            <div class="overlay-content">
              <el-button type="primary" size="small" class="view-btn">
                <i class="el-icon-view"></i> 查看详情
              </el-button>
              <div class="game-stats">
                <span><i class="el-icon-price-tag"></i> {{ i.basePrice }}</span>
                <span><i class="el-icon-collection-tag"></i> {{ i.type }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="game-info">
          <h3 class="game-title">{{ i.name }}</h3>
          <div class="game-tags">
            <el-tag type="danger" effect="dark" class="price-tag">
              <i class="el-icon-money"></i> {{ i.basePrice }}
            </el-tag>
            <el-tag type="info" effect="dark" class="type-tag">
              <i class="el-icon-collection-tag"></i> {{ i.type }}
            </el-tag>
            <el-tag type="success" effect="dark" class="language-tag" v-if="i.containchina">
              <i class="el-icon-chat-dot-round"></i> 支持中文
            </el-tag>
          </div>
          <div class="game-meta">
            <el-tag size="small" effect="plain">
              <i class="el-icon-date"></i> {{ formatDate(i.releaseDate) }}
            </el-tag>
            <el-tag size="small" effect="plain">
              <i class="el-icon-office-building"></i> {{ i.publisherName }}
            </el-tag>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 分页 -->
    <div class="pagination-section">
      <el-pagination @size-change="pshandleSizeChange"
                     @current-change="pshandleCurrentChange"
                     :current-page="pscurrentPage"
                     :page-sizes="[12, 24, 36, 48]"
                     :page-size="pspageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="pstotal">
      </el-pagination>
    </div>

    <!-- 获取账户ID对话框 -->
    <el-dialog title="PSN账户信息"
               :visible.sync="psdialogFormVisible"
               width="30%"
               class="game-dialog"
               v-loading="loading"
               element-loading-text="获取中 预计10s-15s 请稍等">
      <el-form>
        <el-form-item label="PSN用户名">
          <el-input v-model="psnname"
                    placeholder="请输入PSN用户名"
                    autocomplete="off">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="psn-info" v-if="psnnameres">
        <div class="info-item">
          <span class="info-label">Account ID (PSPlay)：</span>
          <span class="info-value">{{ psnnameres.encoded_id }}</span>
        </div>
        <div class="info-item">
          <span class="info-label">Encoded ID (Chiaki)：</span>
          <span class="info-value">{{ psnnameres.user_id }}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="psdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="psgetuser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      // 以下为PS游戏参数
      psgameList: [], // PS游戏列表
      pscurrentPage: 1, // PS游戏当前页码，初始值设为1
      pspageSize: 20, // PS游戏每页显示的数量
      pstotal: 0, // PS游戏的总数量
      psinput: '', // PS游戏名称的搜索输入
      psinput1: '', // PS游戏区域的搜索输入
      psinput2: '', // PS游戏语言的搜索输入


      // 其他参数
      psnname: "", // PS用户名
      psdialogFormVisible: false, // PS对话框可见性
      psnnameres: "", // PS用户名查询结果
      region: [ // PS游戏区域选项
        {value: 'zh-hans-hk', label: '港区'},
        {value: 'ja-jp', label: '日区'},
        {value: 'en-us', label: '美区'},
        {value: 'en-gb', label: '英区'}
      ],
      pslanguega: [] // PS游戏语言选项
    };
  },
  methods: {

    // PS游戏方法

    // 搜索PS游戏
    pssearch() {
      this.pscurrentPage = 1;
      this.psgameget();
    },

    // 获取PS游戏数据
    async psgameget() {
      try {
        const res = await axios.get(this.$globle.allurl + "ps/getpspagegame", {
          params: {
            pagenum: this.pscurrentPage,
            pagesize: this.pspageSize,
            name: this.psinput,
            region: this.psinput1,
            screenLanguages: this.psinput2,
          }
        });
        console.log(res)
        this.psgameList = res.data.records;
        this.psgameList.forEach(game => {
          game.containchina = game.screenLanguages.includes("中文");
        });
        this.pstotal = res.data.total;
      } catch (error) {
        console.error("获取PS游戏数据时出错：", error);
      }
    },

    // 切换到PS游戏详情页面
    changeGopsgame(res) {
      this.$router.push({
        name: "psgameDetail",
        query: {
          id: res.id,
        },
      });
    },

    // 处理PS分页大小变化
    pshandleSizeChange(pageSize) {
      this.pspageSize = pageSize;
      this.psgameget();
    },

    // 处理PS当前页变化
    pshandleCurrentChange(currentPage) {
      this.pscurrentPage = currentPage;
      this.psgameget();
    },

    // 获取PS游戏语言数据
    async getpslanguage() {
      try {
        const res = await axios.get(this.$globle.allurl + "ps/psLanguage");
        this.pslanguega = res.data;
      } catch (error) {
        console.error("获取PS游戏语言数据时出错：", error);
      }
    },

    // 获取PS用户数据
    async psgetuser() {
      this.loading = true;
      try {
        const res = await axios.get(this.$globle.allurl + `ps/getuserid/${this.psnname}`);
        this.psnnameres = res.data;
      } catch (error) {
        console.error("获取PS用户数据时出错：", error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(isoString) {
      const date = new Date(isoString);
      // 选择 UTC 时间（保持原日期）
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;

      // 或使用本地时间（根据用户时区自动转换）
      // const year = date.getFullYear();
      // const month = String(date.getMonth() + 1).padStart(2, '0');
      // const day = String(date.getDate()).padStart(2, '0');
    }
  },
  mounted() {
    // 组件挂载完成后获取初始数据
    this.getpslanguage();
    this.psgameget();
  },
  components: {

    // Swiperlist,
  },
};
</script>

<style scoped>
.game-list-container {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.search-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  margin-bottom: 30px;
}

.search-header {
  margin-bottom: 20px;
  text-align: center;
}

.section-title {
  font-size: 28px;
  color: #303133;
  margin: 0 0 10px;
  font-weight: 600;
}

.section-subtitle {
  color: #909399;
  font-size: 16px;
  margin: 0;
}

.search-box {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.search-box .el-input {
  width: 300px;
}

.region-select, .language-select {
  width: 200px;
}

.search-btn, .add-btn {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.search-btn:hover, .add-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  padding: 20px 0;
}

.game-card {
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
  background: white;
  border: none;
}

.game-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0,0,0,0.12);
}

.game-image {
  position: relative;
  overflow: hidden;
}

.game-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.game-card:hover .game-cover {
  transform: scale(1.08);
}

.game-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.7));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.game-card:hover .game-overlay {
  opacity: 1;
}

.overlay-content {
  text-align: center;
  color: white;
}

.game-stats {
  margin-top: 15px;
  font-size: 14px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.game-stats span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.view-btn {
  transform: translateY(20px);
  transition: all 0.3s ease;
  border-radius: 20px;
  padding: 8px 20px;
}

.game-card:hover .view-btn {
  transform: translateY(0);
}

.game-info {
  padding: 20px;
}

.game-title {
  margin: 0 0 15px;
  font-size: 20px;
  color: #303133;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.game-tags {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.game-meta {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding-top: 15px;
  border-top: 1px solid #f0f0f0;
}

.pagination-section {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
}

.game-dialog {
  border-radius: 12px;
}

.dialog-footer {
  text-align: right;
}

.psn-info {
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.info-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.info-label {
  color: #606266;
  font-weight: 500;
  margin-right: 10px;
}

.info-value {
  color: #303133;
  font-family: monospace;
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .search-section {
    padding: 20px;
  }
  
  .section-title {
    font-size: 24px;
  }
  
  .search-box {
    flex-direction: column;
  }
  
  .search-box .el-input,
  .region-select,
  .language-select {
    width: 100%;
  }
  
  .search-btn, .add-btn {
    width: 100%;
  }
  
  .game-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  
  .game-image {
    height: 180px;
  }
}
</style>
